import { cloneDeep, defaultsDeep, isEqual } from 'lodash'
import { DeepPartial } from '../../../models/util.model'
import { GoodsReturnRow } from '../goods-return.model'
import {
  Product,
  ProductSelection,
  ProductType,
  getProductSellingPrice,
} from '../../products'
import { OrderRow } from '../../orders'
import { getCatalogValue } from '../../catalogs'

// Initial states

const ROW_INITIAL_STATE: DeepPartial<GoodsReturnRow> = {
  _id: undefined,
  //expectedQty: 0,
  orderedQty: 1,
  returnedQty: 0,
  scrappedQty: 0,
  unitPrice: 0,
  unitPriceWithTaxes: 0,
  productTaxRate: 0,
  discount: 0,
  discountWithTaxes: 0,
  totalTaxes: 0,
  totalAmount: 0,
  totalAmountWithTaxes: 0,
  product: {
    _id: undefined,
    SKU: undefined,
  },
}

// Init

export function initGoodsReturnRow(
  row: Partial<GoodsReturnRow> = {},
): GoodsReturnRow {
  return defaultsDeep(cloneDeep(row), ROW_INITIAL_STATE)
}

export function createGoodsReturnRows(
  products: ProductSelection[],
): GoodsReturnRow[] {
  return products.map((p) => createGoodsReturnRow(p.product, p.qty))
}

export function createGoodsReturnRow(
  product: Product,
  qty: number | undefined,
  taxRate?: number,
): GoodsReturnRow {
  const productTaxRate = taxRate
    ? taxRate
    : product.taxRate
      ? product.taxRate
      : 0
  const unitPriceWithTaxes = getProductSellingPrice(product)
  const unitPrice = unitPriceWithTaxes / (productTaxRate / 100 + 1)
  const orderedQty = qty || 1

  return defaultsDeep(
    {
      product: {
        _id: product._id,
        SKU: product.SKU,
        name: getCatalogValue<string>(product.name),
      },
      orderedQty,
      productTaxRate,
      unitPriceWithTaxes,
      unitPrice,
    },
    ROW_INITIAL_STATE,
  )
}

export function removeGoodsReturnRow(
  rows: GoodsReturnRow[],
  row: GoodsReturnRow,
): GoodsReturnRow[] {
  return rows.filter((_row) =>
    row._id ? _row._id !== row._id : !isEqual(_row, row),
  )
}

// Parse

export function parseGoodsReturnOrderRow(row: OrderRow): GoodsReturnRow[] {
  const { _id, splitQty, product, ...returnRow } = row
  const { name, SKU } = product
  let rows = []

  switch (product.productType) {
    case ProductType.kit:
      rows =
        product.simpleProducts?.map((prod) => {
          const orderedQty = prod.quantity * returnRow.orderedQty
          return initGoodsReturnRow({
            ...returnRow,
            orderedQty,
            returnedQty: 0,
            product: {
              _id: prod._id,
              name: prod.name,
              SKU: prod.SKU,
            },
            unitPrice: 0,
            unitPriceWithTaxes: 0,
            productTaxRate: 0,
            discount: 0,
            discountWithTaxes: 0,
            totalTaxes: 0,
            totalAmount: 0,
            totalAmountWithTaxes: 0,
          })
        }) || []
      break
    default:
      rows = [
        initGoodsReturnRow({
          ...returnRow,
          returnedQty: 0,
          product: {
            _id: product._id!,
            name,
            SKU: SKU!,
          },
        }),
      ]
  }

  return rows
}

export function parseGoodsReturnOrderRows(rows: OrderRow[]): GoodsReturnRow[] {
  return rows.reduce<GoodsReturnRow[]>(
    (acc, row) => [...acc, ...parseGoodsReturnOrderRow(row)],
    [],
  )
}

// Amounts

export function updateGoodsReturnRowAmounts(
  row: GoodsReturnRow,
  priceTaxes = false,
): GoodsReturnRow {
  // Check ordered and taxRate
  const productTaxRate = !row.productTaxRate ? 0 : +row.productTaxRate
  const returnedQty = !row.returnedQty ? 0 : +row.returnedQty
  const taxRatio = row.productTaxRate / 100 + 1

  let unitPriceWithTaxes = 0
  let unitPrice = 0
  let discountWithTaxes = 0
  let discount = 0
  let totalTaxes = 0
  let totalAmountWithTaxes = 0
  let totalAmount = 0

  if (priceTaxes) {
    unitPriceWithTaxes = !row.unitPriceWithTaxes ? 0 : +row.unitPriceWithTaxes
    unitPrice = unitPriceWithTaxes / taxRatio
    discountWithTaxes = !row.discountWithTaxes ? 0 : +row.discountWithTaxes
    discount = discountWithTaxes / taxRatio

    totalTaxes =
      ((unitPriceWithTaxes * returnedQty - discountWithTaxes) *
        productTaxRate) /
      (100 + productTaxRate)
    totalAmountWithTaxes = unitPriceWithTaxes * returnedQty - discountWithTaxes
    totalAmount = totalAmountWithTaxes / taxRatio
  } else {
    unitPrice = !row.unitPrice ? 0 : +row.unitPrice
    unitPriceWithTaxes = unitPrice * taxRatio
    discount = !row.discount ? 0 : +row.discount
    discountWithTaxes = discount * taxRatio

    totalAmount = unitPrice * returnedQty - discount
    totalTaxes = totalAmount * (productTaxRate / 100)
    totalAmountWithTaxes = totalAmount + totalTaxes
  }

  return {
    ...row,
    productTaxRate,
    returnedQty,
    unitPriceWithTaxes,
    unitPrice,
    discountWithTaxes,
    discount,
    totalTaxes,
    totalAmountWithTaxes,
    totalAmount,
  }
}
