import {
  Component,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core'
import {
  QuerytStringSortOrder,
  TpIdParserPipe,
} from '@evologi/shared/data-access-api'
import { exportGrid } from '@evologi/shared/util-devextreme'
import { DxDataGridComponent } from 'devextreme-angular'
import { DataGridCell } from 'devextreme/excel_exporter'

type GridOperator = 'contains' | '=' | '<>'

export interface GridColumn {
  label: string
  field?: string
  template?: GridColumnTemplate
  type?: 'string' | 'number' | 'date' | 'boolean'
  dateFormat?: string
  sortOrder?: QuerytStringSortOrder
  filters?: GridOperator[]
  enableSorting?: boolean
  enableFiltering?: boolean
  enableHeaderFiltering?: boolean
  width?: number
  minWidth?: number
  columns?: GridColumn[]
  dataSource?: any
  dataLookupKey?: string
  dataKeyField?: string
  dataDisplayField?: string | ((item: any) => void)
  filterValues?: string[]
  canHide?: boolean
}

type GridColumnTemplate =
  | 'targetPath'
  | 'sourcePath'
  | 'aisleColumn'
  | 'areaColumn'
  | 'rackColumn'
  | 'shelfColumn'
  | 'positionColumn'
  | 'compartmentColumn'
  | 'productSku'
  | 'status'
  | 'user'
  | 'roles'
  | 'country'
  | 'price'
  | 'channel'
  | 'reason'
  | 'payment'
  | 'rowsCount'
  | 'rowsOrderedCount'
  | 'rowsReturnedCount'
  | 'rowsScrappedCount'
  | 'attachments'
  | 'packingOrders'
  | 'packingActions'
  | 'packingTime'
  | 'restoreItemEditColumn'

@Component({
  selector: 'op-grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.scss'],
})
export class GridComponent {
  @ViewChild('grid', { static: true }) grid!: DxDataGridComponent

  @Input({ required: true }) dataSource!: any
  @Input({ required: true }) columns!: GridColumn[]
  @Input() dataLookups?: { [lookUpKey: string]: any }
  @Input() totalCount? = 0
  @Input() exportFileName?: string
  @Input() actionsTemplate?: TemplateRef<any>
  @Input() editColumnTemplate?: string
  @Input() editColumnWidth?: number

  // Status
  @Input() initialized? = false
  @Input() filtered? = false
  @Input() error? = false
  @Input() disabled? = false

  // Toggle
  @Input() showGrid = false
  @Input() showClearButton = false

  @Input() enableFiltering = true
  @Input() enablePagination = true
  @Input() enableSelection = false
  @Input() enableLoader = false

  @Output() readonly itemsSelect = new EventEmitter<any[]>()
  @Output() readonly gridReset = new EventEmitter<void>()
  @Output() readonly entityRestore = new EventEmitter<string>()

  // Useful

  clearSelection() {
    this.grid.instance.clearSelection()
  }

  reloadGrid() {
    this.grid.instance.refresh()
  }

  resetGrid() {
    this.grid.instance.state(null)
    this.grid.instance.clearFilter()
    this.grid.instance.clearSelection()
    this.gridReset.emit()
  }

  resetSelection() {
    this.grid.instance.deselectAll()
  }

  exportGrid(
    customizeCell?: (options: {
      gridCell?: DataGridCell
      excelCell?: any
    }) => void,
  ) {
    exportGrid(
      this.grid.instance,
      this.exportFileName || 'export',
      this.exportFileName,
      customizeCell,
    )
  }

  sourcePath(column: any) {
    if (!column.data?.source?._id) {
      return ''
    }
    const pipe = new TpIdParserPipe()
    const result = pipe.transform(column.data.source._id)
    return result.startsWith('TP')
      ? result
      : (column.column.lookup.items.find(
          (l: { _id: any }) => l._id == column.data.source._id,
        )?.path ?? '')
  }

  targetPath(column: any) {
    if (!column.data?.target?._id) {
      return ''
    }
    const pipe = new TpIdParserPipe()
    const result = pipe.transform(column.data.target._id)
    return result.startsWith('TP')
      ? result
      : (column.column.lookup.items.find(
          (l: { _id: any }) => l._id == column.data.target._id,
        )?.path ?? '')
  }
}
