import { HttpErrorResponse } from '@angular/common/http'
import * as Sentry from '@sentry/angular-ivy'

export function initSentry(
  dsn: string,
  isProduction: boolean,
  isLocalhost: boolean,
) {
  Sentry.init({
    dsn: dsn,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
      new Sentry.Replay(),
    ],

    // Performance Monitoring
    // Capture 10% in production, 100% in staging
    tracesSampleRate: isProduction ? 0.1 : 1.0,

    // Trace only this targets
    tracePropagationTargets: isLocalhost
      ? undefined
      : ['https://staging.orderpod.app/api/', 'https://orderpod.app/api/'],

    // Session Replay
    // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysSessionSampleRate: 0.1,
    // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    replaysOnErrorSampleRate: 1.0,
    beforeSend(event, hint) {
      const error = hint?.originalException
      if (error && typeof error === 'object' && 'status' in error) {
        const errorWithStatus = error as { status: number }
        if (errorWithStatus.status >= 401 && errorWithStatus.status < 409) {
          return null
        }
      }
      return event
    },
  })
}
