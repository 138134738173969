import { Order, OrderPackage } from '../../orders'
import {
  Product,
  PRODUCT_BULKY_VOLUME,
  PRODUCT_HEAVY_WEIGHT,
} from '../../products'
import { PackingList, PackingListAction } from '../packing-list.model'

/**
 * Get packing list heavy products
 * @param packingList - The packing list
 * @param orderId - The order id
 * @returns the quantity of heavy products of a packing list
 */
export function getPackingProductsHeavy(
  packingList: PackingList,
  orderId?: string,
): number {
  const actions = packingList.actions
    .filter((action) => (orderId ? action.orderId === orderId : true))
    .filter(
      (action) =>
        action.grossWeight !== undefined &&
        action.grossWeight >= PRODUCT_HEAVY_WEIGHT,
    )

  return actions.reduce((count, action) => count + action.qtyToPack, 0)
}

/**
 * Get packing list bulky products
 * @param packingList - The packing list
 * @param orderId - The order id
 * @returns the quantity of bulky products of a packing list
 */
export function getPackingProductsBulky(
  packingList: PackingList,
  orderId?: string,
): number {
  const actions = packingList.actions
    .filter((action) => (orderId ? action.orderId === orderId : true))
    .filter(
      (action) =>
        action.volume?.total !== undefined &&
        action.volume.total >= PRODUCT_BULKY_VOLUME,
    )

  return actions.reduce((count, action) => count + action.qtyToPack, 0)
}

/**
 * Get packed weight of a packing list order
 * @param packignList - the packing list
 * @param orderId - the order ID
 * @returns the weight packed
 */
export function getPackingActionsWeightPacked(
  packingList: PackingList,
  orderId: string,
): number {
  const orderActions = packingList.actions.filter((a) => a.orderId === orderId)
  return orderActions.reduce(
    (total, action) => getPackingActionWeightPacked(action) + total,
    0,
  )
}

/**
 * Get packages total net weight
 * @param packages - the order package
 * @returns the total net weight in kilograms
 */
export function getPackingPackagesNetWeight(packages: OrderPackage[]): number {
  return packages
    ? packages.reduce(
        (total, pack) => total + (pack.weight - (pack.packWeight || 0)),
        0,
      )
    : 0
}

/**
 * Get weight to pack of a packing list order
 * @param packingList - the packing list
 * @param orderId - the order ID
 * @param packages - the order packages
 * @returns the weight to pack
 */
export function getPackingActionWeightToPack(
  packingList: PackingList,
  orderId: string,
  packages: OrderPackage[],
): number {
  const totalWeightPacked = getPackingActionsWeightPacked(packingList, orderId)
  const packagesWeightPacked = packages.length
    ? getPackingPackagesNetWeight(packages)
    : 0
  return totalWeightPacked - packagesWeightPacked
}

/**
 * Get packing action weight packed
 * @param action - the packing list action
 * @returns the weight packed
 */
export function getPackingActionWeightPacked(
  action: PackingListAction,
): number {
  return action?.grossWeight ? action.grossWeight * action.qtyPacked : 0
}

/**
 * Get packing product packed amount
 * @param packingList - the packing list
 * @param orderId - the order ID
 * @returns the amount of product packed
 */
export function getPackingPackedAmount(
  packingList: PackingList,
  orderId: string,
): number {
  const packedAmount = packingList?.actions
    .filter((action) => action.orderId === orderId)
    .reduce((total, action) => total + action.qtyPacked, 0)
  return packedAmount ?? 0
}
