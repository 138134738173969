<dx-data-grid
  #grid
  id="gridContainer"
  [dataSource]="dataSource"
  [class.d-none]="!initialized"
  [columnMinWidth]="50"
  [rowAlternationEnabled]="true"
  [hoverStateEnabled]="true"
  [allowColumnResizing]="true"
  [syncLookupFilterValues]="false"
  [repaintChangesOnly]="true"
  [columnResizingMode]="'nextColumn'"
  [noDataText]="''"
  (onSelectionChanged)="itemsSelect.emit($event.selectedRowsData || [])"
>
  <dxo-paging [enabled]="enablePagination"></dxo-paging>
  <dxo-load-panel [enabled]="enableLoader && !!initialized"></dxo-load-panel>
  <dxo-filter-row [visible]="enableFiltering"></dxo-filter-row>
  <dxo-header-filter [visible]="enableFiltering"></dxo-header-filter>
  <dxo-export [enabled]="true"></dxo-export>

  <dxo-remote-operations
    [filtering]="true"
    [sorting]="true"
    [grouping]="true"
    [paging]="true"
    [summary]="true"
  >
  </dxo-remote-operations>

  <dxo-paging [pageSize]="15"></dxo-paging>
  <dxo-pager
    [visible]="(!!totalCount && totalCount > 0) || !!error"
    [showPageSizeSelector]="!!totalCount && totalCount > 15"
    [allowedPageSizes]="[5, 15, 30, 50, 100, 200]"
    [showInfo]="true"
    [showNavigationButtons]="true"
  >
  </dxo-pager>

  <dxo-selection
    *ngIf="enableSelection"
    [selectAllMode]="'page'"
    [showCheckBoxesMode]="'always'"
    mode="multiple"
  >
  </dxo-selection>

  <!-- Columns -->
  <ng-container *ngFor="let column of columns">
    <!-- 
      This is a bad thing, I know. 
      But it's not possible to use the ng-templates inside a DevExtreme datagrid -> NestedOptionHost error
    -->
    <!-- Simple column -->
    <dxi-column
      *ngIf="!column.columns"
      [caption]="column.label"
      [dataField]="column.field"
      [dataType]="column.type || 'string'"
      [sortOrder]="column.sortOrder"
      [cellTemplate]="column.template"
      [filterOperations]="column.filters"
      [allowFiltering]="column.enableFiltering || false"
      [allowSorting]="column.enableSorting || false"
      [allowHeaderFiltering]="column.enableHeaderFiltering || false"
      [width]="column.width"
      [minWidth]="column.minWidth"
      [format]="column.dateFormat"
      [filterValues]="column.filterValues"
      [visible]="column.canHide && column.field"
    >
      <dxo-lookup
        *ngIf="column.dataSource"
        [dataSource]="column.dataSource | customStorePagination"
        [valueExpr]="column.dataKeyField"
        [displayExpr]="column.dataDisplayField"
      >
      </dxo-lookup>
      <dxo-lookup
        *ngIf="!column.dataSource && dataLookups && column.dataLookupKey"
        [dataSource]="dataLookups[column.dataLookupKey] | customStorePagination"
        [valueExpr]="column.dataKeyField"
        [displayExpr]="column.dataDisplayField"
      >
      </dxo-lookup>
    </dxi-column>

    <!-- Parent column with sub-columns -->
    <dxi-column *ngIf="column.columns" [caption]="column.label">
      <dxi-column
        *ngFor="let subcolumn of column.columns"
        [caption]="subcolumn.label"
        [dataField]="subcolumn.field"
        [dataType]="subcolumn.type || 'string'"
        [sortOrder]="subcolumn.sortOrder"
        [cellTemplate]="subcolumn.template"
        [filterOperations]="subcolumn.filters"
        [allowFiltering]="subcolumn.enableFiltering || false"
        [allowSorting]="subcolumn.enableSorting || false"
        [allowHeaderFiltering]="subcolumn.enableHeaderFiltering || false"
        [width]="subcolumn.width"
        [minWidth]="subcolumn.minWidth"
        [format]="subcolumn.dateFormat"
      >
        <dxo-lookup
          *ngIf="subcolumn.dataSource"
          [dataSource]="subcolumn.dataSource | customStorePagination"
          [valueExpr]="subcolumn.dataKeyField"
          [displayExpr]="subcolumn.dataDisplayField"
        >
        </dxo-lookup>
        <dxo-lookup
          *ngIf="
            !subcolumn.dataSource && dataLookups && subcolumn.dataLookupKey
          "
          [dataSource]="
            dataLookups[subcolumn.dataLookupKey] | customStorePagination
          "
          [valueExpr]="subcolumn.dataKeyField"
          [displayExpr]="subcolumn.dataDisplayField"
        >
        </dxo-lookup>
      </dxi-column>
    </dxi-column>
  </ng-container>

  <!-- Custom template -->
  <div *dxTemplate="let column of 'targetPath'">
    {{ targetPath(column) }}
  </div>
  <div *dxTemplate="let column of 'sourcePath'">
    {{ sourcePath(column) }}
  </div>
  <div *dxTemplate="let column of 'areaColumn'">
    {{ column.data | locationKind: 'AREA' }}
  </div>
  <div *dxTemplate="let column of 'aisleColumn'">
    {{ column.data | locationKind: 'AISLE' }}
  </div>
  <div *dxTemplate="let column of 'rackColumn'">
    {{ column.data | locationKind: 'RACK' }}
  </div>
  <div *dxTemplate="let column of 'shelfColumn'">
    {{ column.data | locationKind: 'SHELF' }}
  </div>
  <div *dxTemplate="let column of 'positionColumn'">
    {{ column.data | locationKind: 'POSITION' }}
  </div>
  <div *dxTemplate="let column of 'compartmentColumn'">
    {{
      column.data
        | locationKind
          : (column.data.type == 'TICKET_PALLET'
              ? 'TICKET_PALLET'
              : column.data.type == 'TOTE'
                ? 'TOTE'
                : 'COMPARTMENT')
    }}
  </div>
  <div *dxTemplate="let column of 'productSku'">
    <div [opClipboard]="column.value">
      <a
        *ngIf="column.data.product"
        href="/products#product/{{ column.data.product._id }}"
        target="_blank"
      >
        {{ column.value }}
      </a>
    </div>
  </div>
  <div *dxTemplate="let column of 'status'">
    <ng-container *ngIf="column.value as itemStatus">
      <span class="badge badge-status badge-{{ itemStatus | lowercase }}">
        {{ column.value | translate }}
      </span>
    </ng-container>
  </div>
  <div *dxTemplate="let column of 'user'">
    <ng-container *ngIf="dataLookups && dataLookups['users']">
      <div *ngIf="dataLookups['users'] | find: '_id' : column.value as user">
        {{ user.surname }} {{ user.name }}
      </div>
    </ng-container>
  </div>
  <div *dxTemplate="let column of 'channel'">
    <ng-container *ngIf="dataLookups && dataLookups['channels']">
      <div
        *ngIf="dataLookups['channels'] | find: '_id' : column.value as channel"
      >
        {{ channel.name }}
      </div>
    </ng-container>
  </div>
  <div *dxTemplate="let column of 'reason'">
    <ng-container *ngIf="dataLookups && dataLookups['reasons']">
      <div
        *ngIf="dataLookups['reasons'] | find: '_id' : column.value as reason"
      >
        {{ reason.name }}
      </div>
    </ng-container>
  </div>
  <div *dxTemplate="let column of 'payment'">
    <ng-container *ngIf="dataLookups && dataLookups['payments']">
      <div
        *ngIf="dataLookups['payments'] | find: '_id' : column.value as payment"
      >
        {{ payment.name }}
      </div>
    </ng-container>
  </div>
  <div *dxTemplate="let column of 'roles'">
    <ng-container *ngIf="dataLookups && dataLookups['roles']">
      <ng-container *ngIf="column.data['roles'] | map: '_id' as rolesIds">
        {{
          dataLookups['roles']
            | filter: 'INCLUDES' : '_id' : rolesIds
            | map: 'label'
        }}
      </ng-container>
    </ng-container>
  </div>
  <div *dxTemplate="let column of 'country'">
    <ng-container *ngIf="dataLookups && dataLookups['countries']">
      <div
        *ngIf="
          dataLookups['countries']
            | find: 'alpha2Code' : column.value as country
        "
      >
        {{ country.name }}
      </div>
    </ng-container>
  </div>
  <div *dxTemplate="let price of 'price'">
    {{
      price.displayValue
        | currency: price.data.header.currency : 'symbol' : '1.2'
    }}
  </div>
  <div *dxTemplate="let rows of 'rowsCount'">
    {{ rows.data.rows ? rows.data.rows.length : 0 }}
  </div>
  <div *dxTemplate="let rows of 'rowsOrderedCount'">
    {{ rows.data.rows ? (rows.data.rows | sum: 'orderedQty') : 0 }}
  </div>
  <div *dxTemplate="let rows of 'rowsReturnedCount'">
    {{ rows.data.rows ? (rows.data.rows | sum: 'returnedQty') : 0 }}
  </div>
  <div *dxTemplate="let rows of 'rowsScrappedCount'">
    {{ rows.data.rows ? (rows.data.rows | sum: 'scrappedQty') : 0 }}
  </div>
  <div *dxTemplate="let column of 'attachments'">
    <button
      *ngIf="column.data.attachments?.length"
      class="btn btn-link btn-xs"
      type="button"
      tooltip="Allegati presenti"
      container="body"
      placement="left"
    >
      <i class="fad fa-paperclip-vertical"></i>
    </button>
  </div>
  <div *dxTemplate="let packingOrders of 'packingOrders'">
    {{
      (
        packingOrders.value
        | filter: 'INCLUDES' : 'status' : ['PACKED', 'PRINTED']
      ).length
    }}
  </div>
  <div *dxTemplate="let packingActions of 'packingActions'">
    {{ packingActions.value | sum: 'qtyPacked' }}
  </div>
  <div *dxTemplate="let statusHistory of 'packingTime'">
    <div *ngIf="statusHistory.value | statusHistoryTime">
      {{ statusHistory.value | statusHistoryTime }} min
    </div>
  </div>

  <!-- Editing columns -->
  <dxi-column
    *ngIf="showClearButton || actionsTemplate"
    caption=""
    [allowExporting]="false"
    [cellTemplate]="editColumnTemplate ?? 'editColumn'"
    headerCellTemplate="editColumnHeader"
    [fixed]="true"
    fixedPosition="right"
    [width]="editColumnWidth ?? 80"
    alignment="center"
  ></dxi-column>

  <div *dxTemplate="let header of 'editColumnHeader'">
    <op-button
      *ngIf="showClearButton"
      color="primary"
      size="xs"
      icon="fas fa-eraser"
      [fill]="filtered ? 'solid' : 'outline'"
      (buttonClick)="resetGrid()"
    >
      Reset
    </op-button>
  </div>
  <div *dxTemplate="let pack of 'restoreItemEditColumn'">
    <div class="py-1">
      <button
        *ngIf="
          pack.data.status === 'PROCESSING' || pack.data.status === 'PACKED'
        "
        class="btn btn-primary btn-block font-weight-bold"
        type="button"
        (click)="entityRestore.emit(pack.data._id)"
      >
        Ripristina
      </button>
    </div>
  </div>

  <div *dxTemplate="let row of 'editColumn'">
    <ng-container
      *ngIf="actionsTemplate"
      [ngTemplateOutlet]="actionsTemplate"
      [ngTemplateOutletContext]="{ item: row.data }"
    ></ng-container>
  </div>
</dx-data-grid>

<!-- Initializing -->
<op-loader
  *ngIf="!initialized"
  type="grid"
  wrapperClass="p-0"
  [rowsCount]="8"
></op-loader>

<!-- Status -->
<div *ngIf="initialized && (!totalCount || error)" id="grid-empty">
  <!-- Error -->
  <ng-content *ngIf="error; else emptyMessage" select="status-error">
  </ng-content>

  <!-- Empty -->
  <ng-template #emptyMessage>
    <ng-container *ngIf="!totalCount">
      <ng-content *ngIf="filtered" select="status-filtered"> </ng-content>
      <ng-content *ngIf="!filtered" select="status-empty"> </ng-content>
    </ng-container>
  </ng-template>
</div>
