import { EntityStatusAuth, EntityStatusDesc } from '../../models/entity.model'
import { Field } from '../../models/field.model'
import {
  FilterCollection,
  FilterOperator,
  FilterType,
} from '../../models/filter.model'
import { PermissionsScope } from '../policies/policy.model'
import {
  GoodsReturnChangeStatusAction,
  GoodsReturnField,
  GoodsReturnPermission,
  GoodsReturnStatus,
  GoodsReturnViewType,
} from './goods-return.model'

export const GOODS_RETURN_FRAGMENT = 'goods-return'
export const GOODS_RETURN_SCOPE: PermissionsScope = 'goodsReturns'

export const GOODS_RETURN_STATUSES: EntityStatusDesc<GoodsReturnStatus>[] = [
  {
    code: GoodsReturnStatus.returning,
    name: 'In attesa',
    tooltip: 'Reso in attesa della merce.',
    primary: true,
  },
  {
    code: GoodsReturnStatus.received,
    name: 'Ricevuto',
    tooltip: 'Reso ricevuto dal magazzino di competenza.',
    primary: true,
  },
  {
    code: GoodsReturnStatus.closed,
    name: 'Chiuso',
    tooltip: 'Gestione del reso terminata.',
    primary: true,
  },
  {
    code: GoodsReturnStatus.canceled,
    name: 'Annullato',
    tooltip: 'Reso annullato',
    primary: false,
  },
  {
    code: GoodsReturnStatus.processing,
    name: 'In lavorazione',
    tooltip: 'Reso in lavorazione',
    primary: false,
  },
]

export const GOODS_RETURN_ACTIONS: EntityStatusAuth<
  GoodsReturnChangeStatusAction,
  GoodsReturnPermission
> = {
  [GoodsReturnStatus.returning]: {
    permissions: [
      'editHeader',
      'addRows',
      'editRows',
      'saveReturn',
      'cancelReturn',
    ],
    actions: [
      {
        label: 'Segna come ricevuto',
        action: 'receive',
      },
    ],
  },
  [GoodsReturnStatus.received]: {
    permissions: [
      'editHeader',
      'addRows',
      'editRows',
      'saveReturn',
      'cancelReturn',
    ],
    actions: [
      {
        label: 'Segna come in attesa',
        action: 'return',
      },
      {
        label: 'Chiudi il reso',
        action: 'close',
      },
    ],
  },
  [GoodsReturnStatus.closed]: {
    permissions: ['editNotes', 'saveReturn'],
    actions: [
      {
        label: 'Rimetti in lavorazione',
        action: 'receive',
      },
    ],
  },
  [GoodsReturnStatus.canceled]: {
    permissions: ['editNotes', 'saveReturn'],
    actions: [],
  },
}

export const GOODS_RETURN_FIELDS: Field<
  GoodsReturnField,
  GoodsReturnViewType
>[] = [
  {
    label: '# reso',
    field: 'header.returnNumber',
    defaultViews: ['GOODS_RETURNS_LISTING_MAIN'],
    filterOperators: [
      FilterOperator.equal,
      FilterOperator.notEqual,
      FilterOperator.between,
      FilterOperator.lessThan,
      FilterOperator.lessOrEqualThan,
      FilterOperator.moreThan,
      FilterOperator.moreOrEqualThan,
    ],
    filterType: FilterType.number,
  },
  {
    label: 'Data reso',
    field: 'header.date',
    defaultViews: ['GOODS_RETURNS_LISTING_MAIN'],
    filterOperators: [
      FilterOperator.equal,
      FilterOperator.notEqual,
      FilterOperator.between,
      FilterOperator.beforeThan,
      FilterOperator.beforeOrEqualThan,
      FilterOperator.afterThan,
      FilterOperator.afterOrEqualThan,
    ],
    filterType: FilterType.date,
  },
  {
    label: 'Data arrivo',
    field: 'header.arrivalDate',
    filterOperators: [
      FilterOperator.equal,
      FilterOperator.notEqual,
      FilterOperator.between,
      FilterOperator.beforeThan,
      FilterOperator.beforeOrEqualThan,
      FilterOperator.afterThan,
      FilterOperator.afterOrEqualThan,
    ],
    filterType: FilterType.date,
  },
  {
    label: 'Canale',
    field: 'header.channelId',
    defaultViews: ['GOODS_RETURNS_LISTING_MAIN'],
    filterOperators: [FilterOperator.equal],
    filterType: FilterType.simpleSelect,
    filterCollection: FilterCollection.channels,
  },
  {
    label: 'Pagamento',
    field: 'header.paymentId',
    filterOperators: [FilterOperator.equal],
    filterType: FilterType.simpleSelect,
    filterCollection: FilterCollection.payments,
  },
  {
    label: 'Causale',
    field: 'header.reasonId',
    defaultViews: ['GOODS_RETURNS_LISTING_MAIN'],
    filterOperators: [FilterOperator.equal],
    filterType: FilterType.simpleSelect,
    filterCollection: FilterCollection.reasons,
  },
  {
    label: '# ordine riferimento',
    field: 'referenceOrder.number',
    defaultViews: ['GOODS_RETURNS_LISTING_MAIN'],
    filterOperators: [FilterOperator.equal, FilterOperator.notEqual],
    filterType: FilterType.number,
  },
  {
    label: 'Rif. data riferimento',
    field: 'referenceOrder.refDate',
    filterOperators: [FilterOperator.equal],
    filterType: FilterType.date,
  },
  {
    label: 'Rif. ordine riferimento',
    field: 'referenceOrder.refOrder',
    defaultViews: ['GOODS_RETURNS_LISTING_MAIN'],
    filterOperators: [FilterOperator.equal, FilterOperator.notEqual],
    filterType: FilterType.text,
  },
  {
    label: '# ordine reintegro',
    field: 'recoveryOrder.number',
    defaultViews: ['GOODS_RETURNS_LISTING_MAIN'],
    filterOperators: [FilterOperator.equal, FilterOperator.notEqual],
    filterType: FilterType.number,
  },
  {
    label: 'Rif. data reintegro',
    field: 'recoveryOrder.refDate',
    filterOperators: [FilterOperator.equal],
    filterType: FilterType.date,
  },
  {
    label: 'Rif. ordine reintegro',
    field: 'recoveryOrder.refOrder',
    defaultViews: ['GOODS_RETURNS_LISTING_MAIN'],
    filterOperators: [FilterOperator.equal, FilterOperator.notEqual],
    filterType: FilterType.text,
  },
  {
    label: 'Stato destinazione',
    field: 'header.shippingAddress.countryCode',
    filterOperators: [FilterOperator.equal],
    filterType: FilterType.simpleSelect,
    filterCollection: FilterCollection.countries,
  },
  {
    label: 'Stato',
    field: 'status',
    defaultViews: ['GOODS_RETURNS_LISTING_MAIN'],
  },
  {
    label: 'Data rimborso',
    field: 'header.refundDate',
  },
  {
    label: 'Importo rimborso',
    field: 'header.refundAmount',
  },
  {
    label: 'Righe',
    field: 'rowsCount',
  },
  {
    label: 'Pezzi ordinati',
    field: 'productsOrderedCount',
  },
  {
    label: 'Pezzi resi',
    field: 'productsReturnedCount',
  },
  {
    label: 'Pezzi invendibili',
    field: 'productsScrappedCount',
  },
  {
    label: 'Totale',
    field: 'header.totalReturnAmount',
    defaultViews: ['GOODS_RETURNS_LISTING_MAIN'],
  },
  {
    label: 'Note rimborso',
    field: 'header.refundNotes',
  },
  {
    label: 'Allegati',
    field: 'attachments',
    defaultViews: ['GOODS_RETURNS_LISTING_MAIN'],
  },
]
