import { Injectable, OnDestroy } from '@angular/core'
import { Router } from '@angular/router'
import { fromEvent, Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'

@Injectable({
  providedIn: 'root',
})
export class KeyboardShortcutsService implements OnDestroy {
  private destroy$ = new Subject<void>()
  private keys: string[] = []
  private timeout: any

  constructor(private router: Router) {}

  init() {
    fromEvent<KeyboardEvent>(document, 'keydown')
      .pipe(takeUntil(this.destroy$))
      .subscribe((event) => {
        if (
          event.target instanceof HTMLInputElement ||
          event.target instanceof HTMLTextAreaElement
        ) {
          return
        }
        this.keys.push(event.key.toLowerCase())
        if (this.timeout) {
          clearTimeout(this.timeout)
        }
        this.timeout = setTimeout(() => {
          this.keys = []
        }, 500)
        if (this.keys.join('') === 'gf') {
          event.preventDefault()
          this.focusFirstInput()
        } else {
          const route = this.routeParser(this.keys.join(''))
          if (route) {
            this.router.navigate([`/${route}`])
            this.keys = []
          }
        }
      })
  }

  private focusFirstInput(): void {
    const firstInput = document.querySelector(
      `#search-input`,
    ) as HTMLInputElement
    if (firstInput) {
      this.keys = []
      firstInput.focus()
      firstInput.select()
    }
  }

  routeParser(keys: string): string | undefined {
    switch (keys) {
      case 'gp':
        return 'products'
      case 'go':
        return 'orders'
      case 'gr':
        return 'goods-return'
      case 'gl':
        return 'pickings'
      case 'gm':
        return 'movements'
      case 'gt':
        return 'replenishments'
      case 'gd':
        return 'bordereau'
      case 'gg':
        return 'stocks'
      default:
        return
    }
  }

  ngOnDestroy() {
    this.destroy$.next()
    this.destroy$.complete()
  }
}
